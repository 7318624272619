export const keys = [
  "a",
  "s",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "w",
  "e",
  "t",
  "y",
  "u",
  "o",
  "p",
  ";",
  "'",
  "]",
  "\\",
  "ö",
  "ä",
  "+",
  "#",
];
